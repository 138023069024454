<template>
  <div>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.datefrom") }}</label>

            <flat-pickr v-model="filterSelect.dateFrom" class="form-control" :config="{
              altInput: true,
              altFormat: `d-m-Y H${filterSelect.api.value == 'v2/report/profit' ? '' : ':i'}`,
              enableTime: true,
              time_24hr: true,
            }" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.dateto") }}</label>
            <flat-pickr v-model="filterSelect.dateTo" class="form-control" :config="{
              altInput: true,
              altFormat: `d-m-Y H${filterSelect.api.value == 'v2/report/profit' ? '' : ':i'}`,
              enableTime: true,
              time_24hr: true,
            }" />
            <!-- <label class="mr-1 text-danger" v-if="filterSelect.dateFrom"
              >Rango maximo de 30 dias
            </label> -->
          </b-form-group>
        </b-col>

        <b-col v-if="typeUserLoged == 'Root'" md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1">Clientes
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.selectCustomer" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions" label="name" @option:selected="selectCustomer"
              :reduce="(customer) => customer._id" placeholder="Seleccione un cliente" value="">
              <template #option="{ name }">
                <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1" for="customRadioInline1">WL </label>
            <v-select v-model="filterSelect.WL" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="wlOptions"
              label="name" :reduce="(wl) => wl._id" placeholder="Select WL" value="" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1" for="customRadioInline1">Productos
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.selectProduct" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions" label="name" :reduce="(product) => product.productId"
              placeholder="Select product" value="" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1">
              Monedas
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.selectCurrency" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencyssOptions" placeholder="Seleccione la moneda" value="">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1">Api</label>
            <v-select v-model="filterSelect.api" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="apisOptions" placeholder="Seleccione la moneda" value="api" />
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
          <b-col md="6">
            <b-form-group>
              <label class="mr-1">Buscador</label>
              <b-form-input v-model="filterSelect.q" class="d-inline-block" :placeholder="$t('labels.search')" />
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button :disabled="loadingData" @click="inputSelect()" class="text-nowrap" variant="primary">
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{
              $t("labels.search")
            }}</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col v-for="(item, index) in cardCabezera" :key="index" lg="3" sm="6">
        <statistic-card-horizontal :color="item.color" :icon="item.icon" :statistic="item.statistic"
          :statistic-title="item.title" />
      </b-col>
    </b-row>

    <b-card>
      <b-overlay :show="loadingData" variant="transparent" no-wrap />
      <b-modal id="modalResponse" title="PopUp Response" cancel-variant="outline-secondary" scrollable>
        <b-card-text>
          {{ rows }}
        </b-card-text>
      </b-modal>

      <b-row class="mb-1">
        <b-col md="6"></b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end">
          <b-button @click="downloadFile()" class="text-nowrap"
            :variant="tableData.length > 0 ? 'primary' : 'secondary'" :disabled="tableData.length > 0 ? false : true">
            <b-spinner small v-if="exportDataLoading"></b-spinner>
            <feather-icon v-if="!exportDataLoading" :icon="tableData.length > 0 ? 'DownloadIcon' : 'XIcon'"
              class="mr-50" />
            <span v-if="!exportDataLoading" class="text-nowrap">{{
              $t("labels.export")
            }}</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table mode="remote" :columns="columns" :rows="tableData" :totalRows="totalRows"
        :pagination-options="{ enabled: true }" :sort-options="{ enabled: false }">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'type'">
            <!-- debit or credit span danger success -->
            <span :class="props.row.type == 'debit' ? 'badge-danger' : 'badge-success'
              " class="badge">{{ props.row.type == "debit" ? "Debit" : "Credit" }}</span>
          </div>
          <div v-else-if="props.column.field == 'amount'">
            <div>{{ props.row.amount | currency({ symbol: "" }) }}</div>
          </div>
          <!-- profit -->
          <div v-else-if="props.column.field == 'profit'">
            <div>{{ props.row.profit | currency({ symbol: "" }) }}</div>
            <p>{{ totalProfitToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
          </div>
          <!-- debit -->
          <div v-else-if="props.column.field == 'debit'">
            <div>{{ props.row.debit | currency({ symbol: "" }) }}</div>
            <p>{{ totalSalesToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
          </div>
          <!-- credit -->
          <div v-else-if="props.column.field == 'credit'">
            <div>{{ props.row.credit | currency({ symbol: "" }) }}</div>
            <p>{{ totalWinsToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="serverParams.limit" :options="pages" class="mx-1" @input="onPerPageChange" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="serverParams.limit" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="onPageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <hr />
      <h4>Totales USD</h4>
      <vue-good-table compactMode :columns="columnsTotalUSD" :rows="dataItemsTotalsUSD"
        :pagination-options="{ enabled: false }" :sort-options="{ enabled: false }">
        <div slot="emptystate" class="text-center">No hay datos para mostrar</div>
        <div slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'sales'">
            <p>{{ props.row.sales | currency({ symbol: "" }) }} USD</p>
          </div>
          <div v-if="props.column.field == 'wins'">
            <p>{{ props.row.wins | currency({ symbol: "" }) }} USD</p>
          </div>
          <div v-if="props.column.field == 'profit'">
            <p>{{ props.row.profit | currency({ symbol: "" }) }} USD</p>
          </div>
        </div>
        <div slot="table-header-row" slot-scope="props">
          <div :class="props.row.class">
            {{ props.row.label }}
          </div>
        </div>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDate } from "@core/utils/filter";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import moment from "moment";
import { mapActions } from "vuex";
import currencies from "@/store/currencies";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    BSpinner,
    ToastificationContent,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      exportDataLoading: false,
      timeOutSearchCustomer: null,
      showFilter: false,
      loadingData: false,
      tableData: [],
      pages: [20, 40, 60],
      columns: [
        { label: "Username", field: "username" },
        { label: "spins", field: "spin" },
        { label: "sales", field: "debit" },
        { label: "wins", field: "credit" },
        { label: "profit", field: "profit" },
      ],
      currencies: [],
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      currencyssOptions: [
        "ARS",
        "BRL",
        "CLP",
        "COP",
        "EUR",
        "MXN",
        "MYR",
        "PEN",
        "PYG",
        "USD",
        "UYU",
        "VES",
        "XAF",
      ],
      apisOptions: [
        { label: "Api V1", value: "transaction/profit" },
        { label: "Api V2", value: "v2/report/profit" },
      ],
      filterSelect: {
        dateTo: new Date(),
        dateFrom: new Date(),
        selectCustomer: "",
        selectProduct: "",
        selectCurrency: "",
        q: "",
        WL: "",
        api: { label: "Api V2", value: "v2/report/profit" },
      },
      timeoutGetTransactions: null,
      wlOptions: [],
      loadingWlOptions: false,
      totals: {
        totalSales: 0,
        totalSpins: 0,
        totalWins: 0,
        totalProfit: 0,
      },
      minDate: null,
      maxDate: null,
      columnsTotalUSD: [
        { label: "Ventas", field: "sales" },
        { label: "Ganadas", field: "wins" },
        { label: "Ganancia", field: "profit" },
      ],
      dataItemsTotalsUSD: [{
        sales: 0,
        wins: 0,
        profit: 0
      }],
    };
  },
  created() {
    this.filterSelect.dateFrom = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD 00:00");
    this.filterSelect.dateTo = moment().format("YYYY-MM-DD 23:59");
    this.minDate = moment().subtract(29, "days").format("YYYY-MM-DD 00:00");
    this.maxDate = moment().format("YYYY-MM-DD 23:59");
    this.getExchangeRates();
  },
  computed: {
    cardCabezera() {
      return [
        {
          statistic: this.totals.totalSpins,
          color: "success",
          icon: "PlayCircleIcon",
          title: "Total Spins",
        },
        {
          statistic: this.totals.totalSales,
          color: "danger",
          icon: "ActivityIcon",
          title: "Total Sales",
        },
        {
          statistic: this.totals.totalWins,
          color: "success",
          icon: "TrophyIcon",
          title: "Total Wins",
        },
        {
          statistic: this.totals.totalProfit,
          color: "danger",
          icon: "DollarSignIcon",
          title: "Total Profit",
        },
      ];
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    userLoged() {
      return this.$store.getters["usersModule/userLoged"];
    },
  },
  // add filters
  filters: {
    formatDate,
  },

  methods: {
    ...mapActions("reports", ["downloadReportFile"]),
    async getExchangeRates() {
      const res = await this.$store.dispatch("currenciesModules/getCurrencies");
      this.currencies = res;
    },
    totalWinsToUSD(row) {
      for (let data of this.currencies) {
        if (this.filterSelect.selectCurrency == data.currency) {
          row.totalWinsUSD = row.credit * data.rate;
          return row.totalWinsUSD;
        } else if (this.filterSelect.selectCurrency == "USD") {
          row.totalWinsUSD = row.credit;
          return row.totalWinsUSD;
        }
      }
    },
    totalSalesToUSD(row) {
      for (let data of this.currencies) {
        if (this.filterSelect.selectCurrency == data.currency) {
          row.totalSalesUSD = row.debit * data.rate;
          return row.totalSalesUSD;
        } else if (this.filterSelect.selectCurrency == "USD") {
          row.totalWinsUSD = row.debit;
          return row.totalWinsUSD;
        }

      }
    },
    totalProfitToUSD(row) {

      for (let data of this.currencies) {
        if (this.filterSelect.selectCurrency == data.currency) {
          row.profitUSD = row.profit * data.rate;
          return row.profitUSD;
        } else if (this.filterSelect.selectCurrency == "USD") {
          row.totalWinsUSD = row.profit;
          return row.totalWinsUSD;
        }

      }
    },
    downloadFile() {
      this.exportDataLoading = true;
      const VUE_APP_GATEWAY = process.env.VUE_APP_GATEWAY;
      const filter = {
        dateFrom: moment(this.filterSelect.dateFrom)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        dateAt: moment(this.filterSelect.dateTo)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        customerId: this.filterSelect.selectCustomer,
        currency: this.filterSelect.selectCurrency,
        product: this.filterSelect.selectProduct,
        WL: this.filterSelect.WL,
      };
      const finalUrl = VUE_APP_GATEWAY + "report/profit";
      const format = ".xlsx";
      const customerName = this.customerOptions.find(
        (customer) => customer._id == filter.customerId
      );
      const fileTitle = `profit-report-${filter.product
        }-${customerName?.name.toUpperCase()}-(${filter.dateFrom + " - " + filter.dateAt
        })${format}`;

      this.downloadReportFile({
        filter: filter,
        fileTitle: fileTitle,
        url: finalUrl,
      })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("labels.errorDownloadFile"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.message}`,
            },
          });
        })
        .finally(() => (this.exportDataLoading = false));
    },
    async searchCustomer(search, loading) {
      loading(true);
      try {
        clearTimeout(this.timeOutSearchCustomer);

        this.timeOutSearchCustomer = setTimeout(async () => {
          const res = await this.$store.dispatch(
            "customerModule/getCustomers",
            {
              q: search,
              paginate: {
                page: 1,
                perPage: 20,
                sortDesc: true,
              },
            }
          );
          this.customerOptions = res.data.customers;
          loading(false);
        }, 500);

        loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    async getTransactions() {
      if (this.filterSelect.selectCurrency === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You must select a currency",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } else {
        try {
          this.loadingData = true;
          const res = await this.$store.dispatch(
            "reports/getTransactionsProfit",
            { ...this.filterSelect, ...this.serverParams }
          );
          this.tableData = res.data.transactions;
          for (let data of this.tableData) {
            this.dataItemsTotalsUSD[0]["sales"] = this.dataItemsTotalsUSD[0]["sales"] + this.totalSalesToUSD(data);
            this.dataItemsTotalsUSD[0]["wins"] = this.dataItemsTotalsUSD[0]["wins"] + this.totalWinsToUSD(data);  
            this.dataItemsTotalsUSD[0]["profit"] = this.dataItemsTotalsUSD[0]["profit"] + this.totalProfitToUSD(data);
          }
          this.totalRows = res.data.total;
          this.totals = res.data.totals;
        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching transactions list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          this.loadingData = false;
        }
      }
    },
    async getWlOptions() {
      try {
        this.loadingWlOptions = true;
        const customerSelected = this.customerOptions.find(
          (customer) => customer._id == this.filterSelect.selectCustomer
        );
        const res = await this.$store.dispatch("reports/getWlOptions", {
          customerId: customerSelected.customerId,
        });
        this.wlOptions = res.data;
        this.filterSelect.WL = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingWlOptions = false;
      }
    },
    fillZero(number, length) {
      var my_string = "" + number;
      while (my_string.length < length) {
        my_string = "0" + my_string;
      }

      return my_string;
    },
    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.getTransactions();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.getTransactions();
    },
    inputSelect() {
      if (
        (!this.filterSelect.selectCustomer && this.typeUserLoged == "Root") ||
        !this.filterSelect.selectProduct ||
        !this.filterSelect.selectCurrency
      )
        return;
      this.serverParams.page = 1;
      clearTimeout(this.timeoutGetTransactions);
      this.timeout = setTimeout(() => {
        this.getTransactions();
      }, 500);
    },

    selectCustomer(customer) {
      this.productsOptions = customer.products;
      this.filterSelect.selectProduct = "";
      if (customer.products && customer.products.length >= 1) {
        this.filterSelect.selectProduct = customer.products[0].productId;
      }
      this.getWlOptions();
    },
    async getCustomer() {
      if (this.typeUserLoged !== "Root") return;
      const res = await this.$store.dispatch("customerModule/getCustomers", {
        paginate: {
          page: 1,
          perPage: 200,
          sortDesc: true,
        },
      });
      this.customerOptions = res.data.customers;
    },
    async getProducts() {
      if (this.typeUserLoged !== "Root") {
        const res = await this.$store.dispatch("reports/getProducts");
        this.productsOptions = res.data;

        this.filterSelect.selectCustomer = this.userLoged.customerId;
      }
    },
    async getCurrencys() {
      const res = await this.$store.dispatch("reports/getCurrencys");
      //  this.currencyssOptions = res.data.currencys;
    },
    inputDateFrom(date) {
      // minDate restarle 30 dias a la fecha actual
      // maxDate sumarle 30 dias a la fecha actual
      // this.minDate = moment(date).subtract(29, 'days').format('YYYY-MM-DD 00:00:00')
      // this.maxDate = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59:59')
      // this.filterSelect.dateTo = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59')
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    this.getProducts();
    // this.getCurrencys();
    this.getCustomer();
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
